 <template>
  <div>
    <ConflictedGuidesModal
      :conflictedGuides="conflictedGuides"
      
      @update-conflicted-guides="$event => conflictedGuides = $event"
      @update-invalid-guides="updateInvalidGuides"
      @update-provider-guide="updateProviderGuide"
      :clearProviderGuideUpdated="clearProviderGuideUpdated"
    />

    <ApacGuideModal
      :patient="patient"
      :appointmentId="appointmentId"
      :healthPlan="clinicHealthPlan?.health_plan"
      :sus_information_setting="clinicHealthPlan?.sus_information_setting"
      :clinicHealthPlanId="clinicHealthPlan?.id"
      :susGuide=susGuide
      @update-form="updateForm"
      @onHide="clearSusGuideMethod"
    />

    <BpaGuideModal
      :patient="patient"
      :appointmentId="appointmentId"
      :healthPlan="clinicHealthPlan?.health_plan"
      :sus_information_setting="clinicHealthPlan?.sus_information_setting"
      :clinicHealthPlanId="clinicHealthPlan?.id"
      :susGuide=susGuide
      @update-form="updateForm"
      @onHide="clearSusGuideMethod"
    />

    <AihGuideModal
      :patient="patient"
      :appointmentId="appointmentId"
      :healthPlan="clinicHealthPlan?.health_plan"
      :sus_information_setting="clinicHealthPlan?.sus_information_setting"
      :clinicHealthPlanId="clinicHealthPlan?.id"
      :susGuide=susGuide
      @update-form="updateForm"
      @onHide="clearSusGuideMethod"
    />
  </div>
</template>

<script>
import api from '@/modules/sus/manageGuides/api'
import { saveAs } from 'file-saver'
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import { showOnlending } from '@/utils/permissionsHelp';
import { handleGuideSelected } from '@/utils/invoicingSusHelper'

export default {
  name: 'SusGuidesModalsContainer',
  components: {
    ConflictedGuidesModal: () => import('@/components/Sus/Modal/ConflictedGuidesModal'),
    ApacGuideModal: () => import('@/components/Sus/Guides/ApacGuideModal'),
    BpaGuideModal: () => import('@/components/Sus/Guides/BpaGuideModal'),
    AihGuideModal: () => import('@/components/Sus/Guides/AihGuideModal'),
  },
  props: {
    susGuide: Object,
    clinicHealthPlan: Object,
    required_fields: Object,
    patient: Object,
    appointmentId: String,
    getLastSusGuide: Function,
    clearSusGuide: {
      type: Function,
      default: () => {}
    },
    getClinicHealthPlan: Function,
    setSusGuide: Function,
    getGuide: Function
  },
  data: () => ({
    clinic: getCurrentClinic(),
    hospitalizationGuidesNumbers: [],
    guideNumberExistsInBatch: false,
    guideNumberExists: false,
    guideType: null,
    providerGuide: null,
    providerGuideUpdated: null,
    conflictedGuides: [],
    form: {},
    contracteds: [],
    settingProfessional: null,
    susGuideOtherExpense: {},
  }),
  methods: {
    showOnlending,
    async print(susGuide) {
      const isLoading = this.$loading.show()
      try {
        const susGuideAudit = await this.api.getSusGuideAudits(susGuide.id).then(({data}) => {
          if(data?.length){
            const sortedArray = data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            return sortedArray[0];
          } else {
            return null;
          }
        })
        
        // Puxa o nome de quem criou a guia da SusGuideAudit, caso não encontre, usa o nome de quem está imprimindo a guia
        const userName = susGuideAudit?.person?.name || getCurrentUser().name;
        const isExpense = Boolean(susGuide.expensable_id);

        const { data } = await this.api.printSusGuide(susGuide.id, userName, isExpense) 
        const blob = new Blob([data], { type: 'application/pdf' })
        saveAs(blob, this.getFileName(susGuide))
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    getFileName(susGuide) {
      if(susGuide.expensable_id) return `Guia outras despesas`

      const type = susGuide.guide_type.split('\\').at(-1)
      const types = {
        SusGuideConsultation: `Guide de consulta - ${susGuide.provider_guide}`,
        SusGuideSpSadt: `Guia SP/SADT - ${susGuide.provider_guide}`,
        SusGuideHospitalizationRequest: `Guia solicitação de internação - ${susGuide.provider_guide}`,
        SusGuideFee: `Guia de honorário - ${susGuide.provider_guide}`,
        SusGuideHospitalizationSummary: `Guia resumo de internação - ${susGuide.provider_guide}`,
        SusGuideOtherExpense: `Guia outras despesas`
      }
      return types[type]
    },
    openHospitalizationRequestModal() {
      this.$bvModal.show('hospitalization_request_guide_modal')
    },
    async getHospitalizationGuidesNumbers() {
      try {
        this.hospitalizationGuidesNumbers = []
        const { data } = await this.api.getHospitalizationRequestGuides(this.patient?.id, this.invoicingSusSetting?.id)
        data.map(guide => {
          this.hospitalizationGuidesNumbers.push({
            label: guide.provider_guide,
            value: guide.guide.id
          })
        }).sort((a, b) => b - a)
        this.hospitalizationGuidesNumbers = this.hospitalizationGuidesNumbers.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null))
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async validateProviderGuide(providerGuide) {
      this.guideNumberExistsInBatch = false
      this.guideNumberExists = false
      try {
        this.providerGuide = parseInt(providerGuide)
        const { data } = await this.api.guideNumberExist(providerGuide, this.clinicHealthPlan?.id)
        this.guideNumberExistsInBatch = data.in_batch
        this.guideNumberExists = data.exists
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getConflictedGuideNumbers(originalProviderGuide, providerGuideEdit, guideType) {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getConflictedGuideNumbers(originalProviderGuide, providerGuideEdit, this.clinicHealthPlan?.id , guideType)
        this.conflictedGuides = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    updateForm({ form, providerGuide}) {
      if (form) {
        this.form = form
        this.$set(this.form, 'provider_guide_prop', providerGuide)
      }
    },
    async clearSusGuideMethod() {
      this.hospitalizationGuidesNumbers = []
      this.guideNumberExistsInBatch = false
      this.guideNumberExists = false
      this.guideType = null
      this.providerGuide = null
      this.providerGuideUpdated = null
      this.conflictedGuides = []
      this.form = {}
      this.clearSusGuide()

      if(this.getClinicHealthPlan){
        // await this.getClinicHealthPlan(this.clinicHealthPlan?.invoicing_sus_setting?.id)
      }
    },
    updateInvalidGuides(value) {
      this.guideNumberExistsInBatch = value
      this.guideNumberExists = value
    },
    clearProviderGuideUpdated() {
      this.providerGuideUpdated = null
    },
    updateProviderGuide(value) {
      this.providerGuideUpdated = parseInt(value)
    },
    async getProfessionals() {
      try {
        const { data } = await this.api.getInvoicingSusSettingProfessionals(
          this.invoicingSusSetting?.id
        )
        const contracteds = data.map(item => {
          return {
            label: item.professional.name,
            value: item.professional.name,
            operator_code: item.operator_code,
            cnes: this.invoicingSusSetting?.CNES,
            code_type: item.code_type
          }
        })

        this.contracteds = contracteds;
        if(!this.contracteds.find(el => el === this.settingProfessional)){
          this.contracteds = this.contracteds.concat(this.settingProfessional)
          this.orderContracteds()
        }
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    orderContracteds(){
      const contractedsValue = [...this.contracteds]
      contractedsValue.sort(function (a, b) {
        if (a.value > b.value) {
          return 1;
        }
        if (a.value < b.value) {
          return -1;
        }
        return 0;
      })
      this.contracteds = contractedsValue
    },
    createOtherExpensesGuide(providerGuide) {
      this.susGuideOtherExpense = {}
      const modal = handleGuideSelected('other_expenses_guide')
      this.$bvModal.show(modal)
      setTimeout(() => {
        this.providerGuideUpdated = providerGuide
      }, 800)
    },
    async openOtherExpensesGuide(otherExpenseId) {
      try {
        const { data } = await api.getOtherExpenseGuide(otherExpenseId)
        this.susGuideOtherExpense = data
        this.$bvModal.show('other_expenses_guide_modal')
      } catch (error) {
        this.$toast.error(error.message)
      } 
    },
    async openSpsadtGuide(spsadtId) {
      const data = {
        id: spsadtId
      }
      await this.getGuide(data)
      this.$bvModal.show('spsadt-guide-modal')
    }
  },
  computed: {
    providerGuideReadonly() {
      return Boolean(this.susGuide?.sus_batch_id)
    },
    invoicingSusSetting() {
      return null
    }
  },
  watch: {
    guideNumberExistsInBatch: {
      handler(newValue) {
        if (newValue) {
          this.$toast.error('Este Nº de guia já está em um lote, tente novamente')
        }
      },
      immediate: true
    },
    guideNumberExists: {
      async handler(newValue) {
        /* const originalProviderGuide = this.susGuide?.id ? this.susGuide.provider_guide : this.clinicHealthPlan?.invoicing_sus_setting?.sus_information?.current_guide_number
        if (parseInt(this.providerGuide) === this.providerGuideUpdated) return

        if (newValue && !this.guideNumberExistsInBatch) {
          await this.getConflictedGuideNumbers(originalProviderGuide, this.providerGuide, this.guideType)
          if (!this.form?.id) {
            this.conflictedGuides.unshift(this.form)
          }
          this.$bvModal.show('conflicted-guides-modal')
        } */
      },
      immediate: true
    },
    invoicingSusSetting: {
      async handler(value) {
        if (!value?.id) return
        this.contracteds = []
        this.settingProfessional = {
          label: value?.hired_executant_name,
          value: value?.hired_executant_name,
          operator_code: value?.hired_executant_code,
          cnes: value?.CNES,
          code_type: value?.provider_code // 'Código do Contratado na Operadora'
        }
        await this.getProfessionals()
      },
      deep: true,
    }
  }
}
</script>
